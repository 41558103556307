const state = {
    signatureRequestsCount : 0,
    declinedDocumentsCount : 0,
    overdueDocumentsCount : 0,
    displaySignatureTabs : false,
    displaySigned : false
}

const actions = {

    // PROJECTS
    async GET_DOCUMENTS({dispatch,rootState},body = null) {

        let route = body ? `documentflow/documents/${body}` : `documentflow/documents`
        return await dispatch('ajax/SEND_NEW',
            {
                route:route,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async GET_NEWSLETTER_DOCUMENTS({dispatch,rootState}  = null) {

        let route =  `documentflow/newsletter/documents`
        return await dispatch('ajax/SEND_NEW',
            {
                route:route,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },



    async GET_DOCUMENT_DETAILS({dispatch,rootState} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/document/details/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async ADD_EDIT_DOCUMENT({dispatch,rootState} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/document`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            },{ root : true });
    },
    async DELETE_DOCUMENT({dispatch,rootState} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/document`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            },{ root : true });
    },
    async GET_SIGNATURE_REQUESTS({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/signature_requests`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async GET_SIGNATURE_REQUESTS_COUNT({dispatch,rootState,state}) {

        let result =  await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/signature_requests/count`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });

        if ( !result ) {
            return
        }
        state.signatureRequestsCount = result.signature_requests_count || 0
        state.declinedDocumentsCount = result.declined_count || 0
        state.overdueDocumentsCount = result.overdue_count || 0
    },

    async SIGN_DOCUMENT({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/sign/document`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body
            },{ root : true });
    },
    async DECLINE_DOCUMENT({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/decline/document`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body
            },{ root : true });
    },
    async UPLOAD_EXTRA_SIGNATURE({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/sign/extra_signature`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body
            },{ root : true });
    },


    async CHECK_DOCUMENT_PUBLIC({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/check/document/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    // SETTINGS

    async GET_DOCUMENTS_SETTINGS({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/settings`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async ADD_EDIT_DOCUMENTS_CATEGORY({dispatch,rootState} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/category`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            },{ root : true });
    },
    async DELETE_DOCUMENT_CATEGORY({dispatch,rootState} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/category/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async ADD_EDIT_DOCUMENT_TYPE({dispatch,rootState} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/type`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            },{ root : true });
    },
    async DELETE_DOCUMENT_TYPE({dispatch,rootState} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/type/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async ADD_EDIT_DOCUMENT_TYPE_DATA({dispatch,rootState} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/type_data`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            },{ root : true });
    },
    async DELETE_DOCUMENT_TYPE_DATA({dispatch,rootState} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/type_data`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
                data : body
            },{ root : true });
    },
















}

export default {
    namespaced:true,
    actions,
    state
}